import axios from "axios";
import { IChannelOption } from "./pages/Settings";

export const API_URL = "https://platform.vestaboard.com";

export const getSubscriptionCreds = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  const data = response.data as any;
  const subscriptionId = data["subscription"]["id"];
  const apiKey = data["subscription"]["installation"]["apiCredential"]["key"];
  const apiSecret =
    data["subscription"]["installation"]["apiCredential"]["secret"];

  return {
    subscriptionId,
    apiKey,
    apiSecret,
  };
};

export const getSubscriptionInfo = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  return response.data as any;
};

export const checkSetupComplete = async (boardId: string) => {
  const response = await axios.post("/api/checksetup", {
    boardId,
  });
  const data = response?.data as {
    setupComplete: boolean;
    slackTeamName: string;
    syncedChannels: Array<IChannelOption>;
  };
  return data;
};

export const getChannels = async (
  boardId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.get(
    `/api/channels?boardId=${boardId}&subscriptionConfigToken=${encodeURIComponent(
      subscriptionConfigToken
    )}`
  );
  const data = response?.data as {
    channels: Array<IChannelOption>;
  };
  return data;
};

export const syncToChannel = async (
  channels: Array<IChannelOption>,
  boardId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.post("/api/syncchannels", {
    boardId,
    channels,
    subscriptionConfigToken,
  });
  const data = response?.data as {
    channels: boolean;
  };
  return data;
};

export const removeBoard = async (
  subscriptionConfigToken: string,
  boardId: string
) => {
  const headers = {
    Authorization: subscriptionConfigToken,
  };
  const response = await axios.delete(`/api/board/${boardId}`, {
    headers,
  });
  const data = response?.data as {
    channels: boolean;
  };
  return data;
};

export const updateSubscriptionTitle = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  newTitle: string
) => {
  await axios.post("/api/subscription", {
    subscriptionId,
    subscriptionConfigToken,
    newTitle,
  });
};
